import debounce from 'lodash.debounce';
// @ts-ignore
import * as imgs from './images/**/*';
// @ts-ignore
// import bulb1 from './images/bulb/1-fs8.png';

// document.getElementById('fuck-weixin').style.visibility = 'hidden';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const OW = 400;
const OH = 711;
const W = 1080;
const H = 1920;

enum State {
  STARTING,
  SUCCESS,
}

let state = State.STARTING;

function fromChosen(a: number[]): string {
  switch (a.join('')) {
    case '3012':
    case '2103':
      return 'bulb';

    case '0321':
    case '1230':
      return 'bulb2';

    case '1023':
    case '3201':
      return 'beauty';

    case '0132':
    case '2310':
      return 'beauty2';

    case '1032':
    case '2301':
      return 'client';
    
    case '0123':
    case '3210':
      return 'client2';

    case '2031':
    case '1302':
      return 'expert';
    
    case '3120':
    case '0213':
      return 'expert2';

    case '3102':
    case '2013':
      return 'info';
    
    case '0231':
    case '1320':
      return 'info2';

    case '3021':
    case '1203':
      return 'believe'

    case '0312':
    case '2130':
      return 'believe2';

    default:
      return '';
  }
}

class Point {
  x: number
  y: number

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  dis2(p2: Point): number {
    return (p2.x - this.x) * (p2.x - this.x) +
        (p2.y - this.y) * (p2.y - this.y);
  }

  dis(p2: Point): number {
    return Math.sqrt(this.dis2(p2));
  }
}

const points = [
  new Point(407, 815),
  new Point(407, 1048),
  new Point(666, 1048),
  new Point(666, 815),
];

type Img = HTMLImageElement

class Pattern {
  name: string
  title_t: string
  // text: string

  imgs?: Img[]
  title?: Img
  text?: Img
  line?: [Img, Img, Img]
  re?: Img
}

let logo = null;

{
  const img = new Image();
  img.src = imgs['logo-fs8.png'];
  logo = img;
}

const patterns: Pattern[] = [
  {
    name: 'bulb',
    // text: '在持续进阶的道路上，我们面临着挑战，也一直在用创新征服着挑战。为成就客户，步履不停',
    title_t: '创新',
  },
  {
    name: 'bulb2',
    // text: '在持续进阶的道路上，我们面临着挑战，也一直在用创新征服着挑战。为成就客户，步履不停',
    title_t: '创新',
  },
  {
    name: 'client',
    title_t: '客户',
  },
  {
    name: 'client2',
    title_t: '客户',
  },
  {
    name: 'expert',
    title_t: '专家',
  },
  {
    name: 'expert2',
    title_t: '专家',
  },
  {
    name: 'beauty',
    title_t: '美好',
  },
  {
    name: 'beauty2',
    title_t: '美好',
  },
  {
    name: 'believe',
    title_t: '信任',
  },
  {
    name: 'believe2',
    title_t: '信任',
  },
  {
    name: 'info',
    title_t: '智慧信息服务',
  },
  {
    name: 'info2',
    title_t: '智慧信息服务',
  },
  // {
  //   name: 'butterfly',
  //   // text: '那些你希冀的美好：更公平的社会、更美好的校园、更有效的市场监管、更安全的国家信息……我们在用智慧的信息服务来实现',
    // title: '美好',
  // },
  // {
  //   name: 'butterfly2',
  //   // text: '那些你希冀的美好：更公平的社会、更美好的校园、更有效的市场监管、更安全的国家信息……我们在用智慧的信息服务来实现',
  //   // title: '美好',
  // },
];

let in_: Img = null;

(async () => {
  const canvas = <HTMLCanvasElement>document.getElementById('canvas');
  // const text = <HTMLDivElement>document.getElementById('text');
  const wrapper = <HTMLDivElement>document.getElementById('wrapper');
  // audio.addEventListener('canplay', () => {

  // })

  const scale = Math.min(window.innerWidth / OW, window.innerHeight / OH);
  console.log(scale);
  console.log(window.innerWidth);
  let RW = OW;
  let RH = OH;
  if (scale < 0.98) {
    RW *= scale;
    RH *= scale;
    // canvas.style.marginTop = `${-0.087*667*scale}px`
  }

  canvas.style.width = `${RW}px`;
  canvas.style.height = `${RH}px`;
  wrapper.style.width = `${RW}px`;

  canvas.setAttribute('width', `${W}px`);
  canvas.setAttribute('height', `${H}px`);

  const ctx = canvas.getContext('2d');
  ctx.fillStyle = 'black';
  ctx.strokeStyle = 'black';
  ctx.lineWidth = 28;

  const chosen: [Point, number][] = [];

  let cursor: Point = null;

  const repaint = () => {
    if (state != State.STARTING) {
      return;
    }

    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, W, H);

    if (chosen.length === 0 && in_) {
      ctx.drawImage(in_, 0, 0);
    }

    for (let i = 0; i < 4; i++) {
      drawPoint(points[i], "black");
    }

    for (let i = 0; i < chosen.length - 1; i++) {
      const p1 = chosen[i][0];
      const p2 = chosen[i + 1][0];
      drawLine(p1, p2);
      drawPoint(p1, 'red');
      drawPoint(p2, 'red');
    }

    if (cursor && chosen.length > 0) {
      const plast = chosen[chosen.length - 1][0];
      drawLine(plast, cursor);
    }

    ctx.drawImage(logo, 0, 0);
  };

  let e1 = setInterval(repaint, 20);

  function drawPoint(p: Point, s: string = 'black') {
    const os = ctx.fillStyle;
    ctx.fillStyle = s;
    ctx.beginPath();
    ctx.arc(p.x, p.y, 14, 0, 2 * Math.PI, false);
    ctx.fill();
    ctx.closePath();
    ctx.fillStyle = os;
  }

  function drawLine(p1: Point, p2: Point) {
    ctx.beginPath();
    ctx.moveTo(p1.x, p1.y);
    ctx.lineTo(p2.x, p2.y);
    ctx.stroke();
    ctx.closePath();
  }

  // const m: Map<string, HTMLImageElement[]> = new Map();

  // prepare
  {
    const img = new Image();

    img.src = imgs['in-fs8.png'];
    in_ = img;
  }

  patterns.forEach(async p => {
    const dir = imgs[p.name];
    const cnt = Object.keys(dir).filter(x => /^\d+-fs8\.png$/.test(x)).length;

    if (!p.line) {
      p.line = [null, null, null];
    }

    if (!p.imgs) {
      p.imgs = [];
    }

    for (let i = 0; i < 3; ++i) {
      const img = new Image();
      img.src = dir[`l${i+1}-fs8.png`];
      p.line[i] = img;
    }

    for (let i = 0; i < cnt; ++i) {
      const img = new Image();
      img.src = dir[`${i+1}-fs8.png`];
      p.imgs.push(img);
      // if (m.has(p.name)) {
      //   m.get(p.name).push(img);
      // } else {
      //   m.set(p.name, [img]);
      // }
    }

    {
      const img = new Image();
      img.src = dir['title-fs8.png'];
      p.title = img;
    }

    {
      const img = new Image();
      img.src = dir['text-fs8.png'];
      p.text = img;
    }

    {
      const img = new Image();
      img.src = dir['re-fs8.png'];
      p.re = img;
    }
  });

  let s = new Set(points.map((p, i): [Point, number] => [p, i]));

  async function draw(name: string) {
    ctx.drawImage(logo, 0, 0);

    const p = patterns.find(p2 => p2.name === name);
    const { imgs, title, text, line, re } = p;

    for (let i = 0; i < line.length; ++i) {
      ctx.drawImage(line[i], 0, 0);
    }

    console.log(imgs.length);

    for (let i = 0; i < imgs.length; ++i) {
      try {
        ctx.drawImage(imgs[i], 0, 0);
      } catch (err) {
        console.error(err);
        console.log(i, imgs[i]);
      }
      if (i !== imgs.length - 1) {
        await sleep(280);
      }
    }

    await sleep(500);
    ctx.drawImage(title, 0, 0);

    await sleep(400);
    let alpha = 0.1;
    ctx.globalAlpha = alpha;

    for (let i = 0; i < 20; ++i) {
      ctx.drawImage(text, 0, 0);
      await sleep(50);
    }

    ctx.globalAlpha = 1.0;

    ctx.drawImage(re, 0, 0);

    const f = () => {
      state = State.STARTING;
      chosen.length = 0;
      s = new Set(points.map((p, i): [Point, number] => [p, i]));
      repaint();
      e1 = setInterval(repaint, 20);
      canvas.removeEventListener('touchend', f);
    }

    canvas.addEventListener('touchend', f);
  }

  const handle = debounce((p: Point) => {
    console.log('handling');
    if (s.size === 0) {
      return;
    }

    let [min, idx, p3] = [Infinity, -1, null];
    s.forEach(([p2, idx2]) => {
      const d = p.dis2(p2);
      if (d < min) {
        [min, idx, p3] = [d, idx2, p2];
      }
    });

    if (p.dis(p3) > 100) {
      return;
    }

    chosen.push([p3, idx]);

    s = new Set(
        Array.from(s.values()).filter(([p, idx3]) => {return idx3 != idx}));

    if (s.size === 0) {
      clearInterval(e1);
      cursor = null;
      repaint();

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, W, H);

      const name = fromChosen(chosen.map(x => x[1]));
      draw(name);
      // text.innerText = patterns.find(p => p.name === name).text;
      // document.title = patterns.find(p => p.name === name).title_t;
      const audio = <HTMLAudioElement>document.getElementById('audio');
      if (audio) {
        audio.play();
      }
    }
  }, 10);

  // canvas.addEventListener('click', (ev) => {
  //   const x = (ev.clientX - canvas.offsetLeft) * W / OW;
  //   const y = (ev.clientY - canvas.offsetTop) * H / OH;
  //   handle(new Point(x, y));
  // });

  let loaded = false;
  canvas.addEventListener('touchstart', (ev) => {
    const audio = <HTMLAudioElement>document.getElementById('audio');
    if (audio && !loaded) {
      audio.load();
      loaded = true;
    }

    if (state !== State.STARTING) {
      return;
    }

    if (ev.changedTouches.length > 0 && s.size === points.length) {
      const touch = ev.changedTouches[0];
      const x = (touch.clientX - canvas.offsetLeft) * W / RW;
      const y = (touch.clientY - canvas.offsetTop) * H / RH;
      cursor = new Point(x, y);
      handle(new Point(x, y));
    }
  });

  canvas.addEventListener('touchmove', (ev) => {
    ev.preventDefault();
    if (state !== State.STARTING) {
      return;
    }

    if (ev.changedTouches.length === 0) {
      return;
    }

    const touch = ev.changedTouches[0];
    const x = (touch.clientX - canvas.offsetLeft) * W / RW;
    const y = (touch.clientY - canvas.offsetTop) * H / RH;
    cursor = new Point(x, y);
    handle(new Point(x, y));
  });

  canvas.addEventListener('touchend', () => {
    cursor = null;
  });
})();
